<template>
  <ChecCard
    tailwind="p-4 mb-4"
    inner-class="customer-notes"
  >
    <ChecHeader variant="card" :title="$t('customer.notesTitle')" />
    <NotesCard
      state-key="customers/notes"
      :context="{ customerId: customerId }"
    />
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
} from '@chec/ui-library';
import NotesCard from '@/components/NotesCard.vue';

export default {
  name: 'CustomerNotesCard',
  components: {
    ChecCard,
    ChecHeader,
    NotesCard,
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.customer-notes .notes {
  @apply mt-4;
}
</style>
