<template>
  <ChecCard
    inner-class="details-card"
    tailwind="p-4"
  >
    <ChecLoading v-if="isLoading" />
    <ChecHeader
      :title="$t('general.details')"
      variant="card"
      header-tag="h3"
    />
    <template>
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            :value="customer.firstname"
            :label="$t('general.firstName')"
            name="firstname"
            :variant="errors && errors['firstname'] ? 'error' : ''"
            @input="value => change('firstname', value)"
          />
          <span v-if="errors['firstname']" class="input-wrapper__error">
            {{ errors['firstname'] }}
          </span>
        </div>
        <div class="input-wrapper">
          <TextField
            :value="customer.lastname"
            :label="$t('general.lastName')"
            name="lastname"
            :variant="errors && errors['lastname'] ? 'error' : ''"
            @input="value => change('lastname', value)"
          />
          <span v-if="errors['lastname']" class="input-wrapper__error">
            {{ errors['lastname'] }}
          </span>
        </div>
      </div>
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            :value="customer.email"
            :label="$t('general.emailAddress')"
            type="email"
            name="email"
            :variant="errors && errors['email'] ? 'error' : ''"
            required
            @input="value => change('email', value)"
          />
          <span v-if="errors['email']" class="input-wrapper__error">
            {{ errors['email'] }}
          </span>
        </div>
        <div class="input-wrapper">
          <TextField
            :value="customer.phone"
            :label="$t('general.phoneNumber')"
            name="phone"
            :variant="errors && errors['phone'] ? 'error' : ''"
            @input="value => change('phone', value)"
          />
          <span v-if="errors['phone']" class="input-wrapper__error">
            {{ errors['phone'] }}
          </span>
        </div>
      </div>
      <div class="input-wrapper">
        <TextField
          :value="customer.external_id"
          :label="$t('customer.externalId')"
          name="external_id"
          :variant="errors && errors['external_id'] ? 'error' : ''"
          @input="value => change('external_id', value)"
        />
        <span v-if="errors['external_id']" class="input-wrapper__error">
          {{ errors['external_id'] }}
        </span>
      </div>
    </template>
  </ChecCard>
</template>

<script>
import {
  ChecHeader,
  ChecLoading,
  ChecCard,
  TextField,
} from '@chec/ui-library';

export default {
  name: 'DetailsCard',
  components: {
    ChecHeader,
    ChecLoading,
    ChecCard,
    TextField,
  },
  model: {
    prop: 'customer',
    event: 'input',
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.customer,
        [prop]: value,
      });
    },
  },
};
</script>
