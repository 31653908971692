<template>
  <ChecCard inner-class="discount-actions" tailwind="p-4">
    <ChecButton
      color="green"
      class="flex-grow"
      :disabled="saving"
      button-type="submit"
      @click="() => $emit('save-customer')"
    >
      {{ saveLabel }}
    </ChecButton>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
} from '@chec/ui-library';

export default {
  name: 'ActionsCard',
  components: {
    ChecButton,
    ChecCard,
  },
  props: {
    saving: Boolean,
  },
  computed: {
    /**
     * The label shown for the "save" button
     *
     * @returns {string}
     */
    saveLabel() {
      return this.saving ? this.$t('general.saving') : this.$t('general.saveChanges');
    },
  },
};
</script>

<style lang="scss">
.discount-actions {
  @apply flex flex-row gap-2;

  &__rule {
    @apply border-gray-200 mt-4 mb-2;
  }

  &__secondary {
    @apply flex justify-end;
  }

  &__more-options {
    @apply flex items-center;
  }
}
</style>
