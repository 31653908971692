var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardTable',{staticClass:"customers-table",attrs:{"align-right-after":5,"columns":[
    _vm.$t('general.emailAddress'),
    _vm.$t('general.firstName'),
    _vm.$t('general.lastName'),
    _vm.$t('customer.totalOrders'),
    _vm.$t('customer.totalSpent'),
    _vm.$t('general.options') ],"show-search":"","state-key":"customers"}},_vm._l((_vm.customers),function(ref){
  var id = ref.id;
  var email = ref.email;
  var firstname = ref.firstname;
  var lastname = ref.lastname;
  var statistics = ref.statistics;
return _c('TableRow',{key:id,attrs:{"id":id,"route-name":"customers.edit"}},[_c('td',[_c('router-link',{attrs:{"to":{ name: 'customers.edit', params: { id: id } }}},[_vm._v(" "+_vm._s(email)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(firstname)+" ")]),_c('td',[_vm._v(" "+_vm._s(lastname)+" ")]),_c('td',[_vm._v(" "+_vm._s(statistics ? statistics.order_count : '—')+" ")]),_c('td',{staticClass:"customers-table__total-spent"},[_vm._v(" "+_vm._s(_vm.totalSpent(statistics))+" ")]),_c('td',[_c('ChecOptionsMenu',{staticClass:"inline-block"},[_c('ChecOption',{on:{"option-selected":function () { return _vm.handleEdit(id); }}},[_vm._v(" "+_vm._s(_vm.$t('general.edit'))+" ")]),_c('ChecOption',{attrs:{"destructive":""},on:{"option-selected":function () { return _vm.handleDelete(id); }}},[_vm._v(" "+_vm._s(_vm.$t('general.delete'))+" ")])],1)],1)])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }