<template>
  <div class="customers-home">
    <DashboardHeader :title="$t('customer.customers')">
      <ChecButton
        color="primary"
        variant="round"
        @click="() => $router.push({ name: 'customers.add' })"
      >
        {{ $t('general.add') }}
        <template #icon>
          <ChecIcon icon="plus" />
        </template>
      </ChecButton>
    </DashboardHeader>

    <IntroSplash
      v-if="showIntroSplash"
      :title="$t('customer.introSplash.title')"
      button
      @click="() => $router.push({ name: 'customers.add' })"
    >
      {{ $t('customer.introSplash.description') }}
    </IntroSplash>

    <CustomersTable
      v-else
      :customers="customers"
      @delete-customer="handleDelete"
    />

    <DashboardPagination state-key="customers" />

    <router-view />
  </div>
</template>

<script>
import {
  ChecButton,
  ChecIcon,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';
import DashboardPagination from '@/components/Pagination.vue';
import IntroSplash from '@/components/IntroSplash.vue';
import crud from '@/mixins/crud';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import CustomersTable from '../components/CustomersTable.vue';

export default {
  name: 'CustomersHome',
  components: {
    ChecButton,
    ChecIcon,
    CustomersTable,
    DashboardHeader,
    DashboardPagination,
    IntroSplash,
  },
  mixins: [crud('customers', true), addNotification, confirm],
  computed: {
    /**
     * Show intro card section when there are no customers and when
     * page is not loading
     *
     * @returns {boolean}
     */
    showIntroSplash() {
      return this.customers.length === 0 && !this.isLoading && this.currentSearchTerm?.length === 0;
    },
  },
  methods: {
    async handleDelete(customerId) {
      if (await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('customer.confirmDelete'),
      )) {
        this.delete(customerId)
          .then(() => {
            this.addNotification(this.$t('customer.deleted'));
          })
          .catch((error) => {
            this.addNotification(error, 'error');
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customers-home {
  @apply w-full;

  &__search {
    @apply mb-4;
  }
}
</style>
