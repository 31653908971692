<template>
  <ChecCard inner-class="customer-actions" tailwind="p-4">
    <ChecHeader
      :title="$t('customer.deleteCustomer')"
      variant="card"
      header-tag="h3"
    />
    <div class="py-4">
      {{ $t('general.cannotBeUndone') }}
    </div>
    <ChecButton
      color="red"
      outline
      variant="small"
      :disabled="!allowDelete || saving"
      button-type="submit"
      @click="() => $emit('delete-customer')"
    >
      {{ $t('general.delete') }}
    </ChecButton>
  </ChecCard>
</template>

<script>
import {
  ChecHeader,
  ChecButton,
  ChecCard,
} from '@chec/ui-library';

export default {
  name: 'ActionsCard',
  components: {
    ChecHeader,
    ChecButton,
    ChecCard,
  },
  props: {
    saving: Boolean,
    allowDelete: Boolean,
  },
};
</script>
