<template>
  <ChecCard
    inner-class="details-card"
    tailwind="p-4"
  >
    <ChecHeader
      :title="$t('customer.orderHistory')"
      variant="card"
      header-tag="h3"
    />
    <template>
      <OrdersTable
        :customer-id="customerId"
        state-key="customers/orders"
      />
    </template>
  </ChecCard>
</template>

<script>
import {
  ChecHeader,
  ChecCard,
} from '@chec/ui-library';
import OrdersTable from '@/modules/customers/components/OrdersTable.vue';

export default {
  name: 'OrderHistory',
  components: {
    ChecHeader,
    ChecCard,
    OrdersTable,
  },
  model: {
    prop: 'customer',
    event: 'input',
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
  },
};
</script>
