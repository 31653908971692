<template>
  <div
    class="card card--background-white card--border-none card--border-style-holo customer-overview"
    :class="{'customer-overview__showing-recent': hasOrderDetails}"
  >
    <div
      class="card__inner-wrapper p-4 card__inner-wrapper--background-white"
    >
      <ChecLoading v-if="isLoading" />
      <template>
        <div class="customer-overview__header">
          <div class="customer-overview__header--details">
            <ChecAvatar class="customer-overview__header--avatar" variant="md" />
            <div>
              <div
                v-if="customerName "
                class="customer-overview__header--name"
              >
                {{ customerName }}
              </div>
              <a
                class="customer-overview__header--email"
                :href="`mailto:${customer.email}`"
              >
                {{ customer.email }}
              </a>
              <div
                v-if="customer.external_id"
                class="customer-overview__header--external_id"
              >
                {{ `${$t('customer.externalId')}: ${customer.external_id}` }}
              </div>
            </div>
          </div>
          <div>
            <ChecButton
              class="card__button"
              color="primary"
              outline
              variant="round"
              @click="showPanel = !showPanel"
            >
              {{ $t('general.edit') }}
            </ChecButton>
          </div>
        </div>
        <div class="customer-overview__stats">
          <div class="customer-overview__stats--overview">
            <div class="customer-overview__stats--stat">
              <div class="customer-overview__stats--stat-header">
                {{ $t('customer.lastOrder') }}
              </div>
              <div class="customer-overview__stats--stat-value">
                {{ daysSinceLastOrder }}
              </div>
            </div>
            <div class="customer-overview__stats--stat">
              <div class="customer-overview__stats--stat-header">
                {{ $t('customer.totalSpent') }}
              </div>
              <div class="customer-overview__stats--stat-value-amount">
                {{ totalSpent }}
              </div>
            </div>
            <div class="customer-overview__stats--stat">
              <div class="customer-overview__stats--stat-header">
                {{ $t('customer.totalOrders') }}
              </div>
              <div class="customer-overview__stats--stat-value">
                {{ customer.statistics ? customer.statistics.order_count : '—' }}
              </div>
            </div>
          </div>
          <div class="customer-overview__stats--created">
            <Timestamp
              :timestamp="parseInt(customer.created, 10)"
              :prefix="`${$t('general.created')}:`"
              from-now
            />
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="hasOrderDetails"
      class="customer-overview__recent-order"
    >
      <div class="customer-overview__recent-order--header">
        {{ $t('customer.recentOrder') }}
      </div>
      <div class="customer-overview__recent-order--row-status">
        <div class="flex flex-row gap-4">
          <ChecDataPill
            filled
            color="gray"
          >
            #{{ customer.last_order.customer_reference }}
          </ChecDataPill>
          <div>
            {{ $t('general.items', { count: customer.last_order.order.line_items.length }) }}
          </div>
        </div>
        <ChecDataPillGroup>
          <ChecDataPill
            v-for="({ filled, color, text }) in pills(customer.last_order)"
            :key="text"
            :filled="filled"
            :color="color"
          >
            {{ text }}
          </ChecDataPill>
        </ChecDataPillGroup>
      </div>
      <div class="customer-overview__recent-order--row-view">
        <div>
          <div class="customer-overview__recent-order--total-paid">
            {{ $t('customer.totalPaid') }}
          </div>
          <div class="customer-overview__recent-order--value">
            {{ customer.last_order.order_value.formatted_with_symbol }}
          </div>
        </div>
        <ChecButton
          color="secondary"
          text-only
          variant="small"
          icon="right-arrow"
          icon-position="after"
          tag-type="route"
          :to="{ name: 'orders.view', params: { id: customer.last_order.id } }"
        >
          {{ $t('customer.viewOrder') }}
        </ChecButton>
      </div>
    </div>
    <DetailsPanel
      v-if="showPanel"
      :customer="customer"
      :errors="errors"
      :saving="saving"
      @change="change"
      @close="showPanel = !showPanel"
      @handle-save="handleSave"
    />
  </div>
</template>

<script>
import {
  ChecButton,
  ChecLoading,
  ChecAvatar,
  ChecDataPillGroup,
  ChecDataPill,
} from '@chec/ui-library';
import { mapState } from 'vuex';
import Timestamp from '@/components/Timestamp.vue';
import DetailsPanel from './DetailsPanel.vue';

export default {
  name: 'OverviewCard',
  components: {
    ChecButton,
    ChecDataPillGroup,
    ChecDataPill,
    ChecLoading,
    ChecAvatar,
    DetailsPanel,
    Timestamp,
  },
  model: {
    prop: 'customer',
    event: 'input',
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    saving: Boolean,
    isLoading: Boolean,
  },
  data() {
    return {
      showPanel: false,
    };
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    customerName() {
      return this.customer.firstname !== null
        || this.customer.lastname !== null
        ? `${this.customer.firstname} ${this.customer.lastname}`
        : null;
    },
    hasOrderDetails() {
      return !!this.customer.last_order;
    },
    totalSpent() {
      return this.customer.statistics
        ? this.customer.statistics.total_spent.formatted_with_symbol
        : 0;
    },
    daysSinceLastOrder() {
      if (this.customer.statistics) {
        return this.$moment(this.customer.last_order.created_at)
          .format('D MMM YYYY');
      }
      return '—';
    },
  },
  methods: {
    change(details) {
      this.$emit('input', details);
    },
    handleSave() {
      this.$emit('save-customer');
      this.showPanel = false;
    },
    pills({ status, status_payment: statusPayment, status_fulfillment: statusFulfillment }) {
      const colors = {
        status: {
          cancelled: 'gray',
        },
        paymentStatus: {
          not_paid: 'red',
          paid: 'green',
          partially_paid: 'orange',
          pending: 'orange',
          refunded: 'gray',
        },
        fulfillmentStatus: {
          fulfilled: 'green',
          not_fulfilled: 'red',
          partially_fulfilled: 'orange',
          returned: 'gray',
        },
      };

      return [{
        color: colors.paymentStatus[statusPayment],
        filled: true,
        text: this.$t(`order.paymentStatus.${statusPayment}`),
      }, {
        color: status === 'cancelled'
          ? colors.status.cancelled
          : colors.fulfillmentStatus[statusFulfillment],
        filled: false,
        text: this.$t(`order.fulfillmentStatus.${statusFulfillment}`),
      }];
    },
  },
};
</script>

<style lang="scss">
.customer-overview {
  &__showing-recent {
    .card__inner-wrapper {
      @apply rounded-b-none;
    }
  }

  &__header {
    @apply flex flex-row justify-between content-center items-center;

    &--details {
      @apply flex flex-row gap-4 items-center;
    }

    &--name {
      @apply font-bold;
    }

    &--email {
      @apply text-sm text-blue-400;
    }

    &--external_id {
      @apply text-xxs uppercase text-gray-500 pt-1;
    }
  }

  &__stats {
    @apply bg-hologram-light border-gray-300 border rounded p-4 mt-4;

    &--overview {
      @apply flex flex-row justify-between border-b border-gray-300;
    }

    &--stat {
      @apply w-1/3 text-center pb-4;
    }

    &--stat-header {
      @apply text-xxs font-bold text-gray-500 uppercase;
    }

    &--stat-value {
      @apply font-bold text-gray-600;
    }

    &--stat-value-amount {
      @apply font-bold text-green-400;
    }

    &--created {
      @apply p-4 pb-0 text-center; }
  }

  &__recent-order {
    @apply bg-gray-500 text-white p-4 rounded-b-md;

    &--header {
      @apply text-xxs font-bold uppercase pb-4;
    }

    &--row-view,
    &--row-status {
      @apply flex flex-row justify-between;
    }

    &--row-view {
      @apply pt-4;
    }

    &--row-status {
      @apply border-b border-gray-400 pb-4;
    }

    &--total-paid {
      @apply text-xxs font-bold uppercase pb-1;
    }

    &--value {
      @apply text-lg font-bold;
    }
  }
}
</style>
