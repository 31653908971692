<template>
  <DashboardTable
    class="customers-table"
    :align-right-after="5"
    :columns="[
      $t('general.emailAddress'),
      $t('general.firstName'),
      $t('general.lastName'),
      $t('customer.totalOrders'),
      $t('customer.totalSpent'),
      $t('general.options'),
    ]"
    show-search
    state-key="customers"
  >
    <TableRow
      v-for="({ id, email, firstname, lastname, statistics }) in customers"
      :id="id"
      :key="id"
      route-name="customers.edit"
    >
      <td>
        <router-link :to="{ name: 'customers.edit', params: { id: id } }">
          {{ email }}
        </router-link>
      </td>
      <td>
        {{ firstname }}
      </td>
      <td>
        {{ lastname }}
      </td>
      <td>
        {{ statistics ? statistics.order_count : '—' }}
      </td>
      <td class="customers-table__total-spent">
        {{ totalSpent(statistics) }}
      </td>
      <td>
        <ChecOptionsMenu class="inline-block">
          <ChecOption @option-selected="() => handleEdit(id)">
            {{ $t('general.edit') }}
          </ChecOption>
          <ChecOption destructive @option-selected="() => handleDelete(id)">
            {{ $t('general.delete') }}
          </ChecOption>
        </ChecOptionsMenu>
      </td>
    </TableRow>
  </DashboardTable>
</template>

<script>
import { ChecOptionsMenu, ChecOption } from '@chec/ui-library';
import DashboardTable from '@/components/DashboardTable.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'CustomersTable',
  components: {
    TableRow,
    DashboardTable,
    ChecOptionsMenu,
    ChecOption,
  },
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleEdit(id) {
      this.$router.push({ name: 'customers.edit', params: { id } });
    },
    handleDelete(id) {
      this.$emit('delete-customer', id);
    },
    totalSpent(statistics) {
      return statistics
        ? statistics.total_spent.formatted_with_symbol
        : '—';
    },
  },
};
</script>

<style lang="scss" scoped>
.customers-table {
  @apply table-fixed w-full text-sm text-gray-500 mt-4;

  // Email address column
  tr > *:nth-child(n+3) {
    @apply truncate;
  }

  // Options column
  tr > *:nth-child(n+4) {
    @apply text-right w-24;
  }

  &__slug {
    @apply mr-0;
  }

  &--empty {
    @apply text-gray-400;
  }

  &__total-spent {
    @apply text-green-600;
  }
}
</style>
