<template>
  <DashboardTable
    class="orders-table"
    :align-right-after="3"
    :columns="[
      $t('order.orderNumber'),
      $t('order.dateTime'),
      $t('order.status'),
      $t('general.amount'),
    ]"
    state-key="customers/orders"
  >
    <TableRow
      v-for="order in items"
      :id="order.id"
      :key="order.id"
      class="orders-table__row"
      :class="{
        'orders-table__row--sandbox': order.sandbox,
        'orders-table__row--cancelled': order.status === 'cancelled',
      }"
      route-name="orders.view"
    >
      <td>
        <div class="orders-table__reference-cell">
          <!-- Note this link is here to be tab-indexable - but the whole row is clickable -->
          <router-link :to="{ name: 'orders.view', params: { id: order.id } }">
            <div class="orders-table__reference-number">
              #{{ order.customer_reference }}
              <ChecDataPill
                v-if="order.status === 'cancelled'"
                class="orders-table__cancelled"
              >
                {{ $t('general.cancelled') }}
              </ChecDataPill>
            </div>
          </router-link>
        </div>
      </td>
      <td>
        <Timestamp :timestamp="order.created" />
      </td>
      <td>
        <ChecDataPillGroup>
          <ChecDataPill
            v-for="({ filled, color, text }) in pills(order)"
            :key="text"
            :filled="filled"
            :color="color"
          >
            {{ text }}
          </ChecDataPill>
        </ChecDataPillGroup>
      </td>
      <td class="orders-table__price">
        {{ money(order.order_value).full() }}
      </td>
    </TableRow>
  </DashboardTable>
</template>

<script>
import {
  ChecDataPill,
  ChecDataPillGroup,
} from '@chec/ui-library';
import withPropDefinedState from '@/mixins/withPropDefinedState';
import { actions } from '@/lib/pagination';
import Timestamp from '@/components/Timestamp.vue';
import money from '@/lib/helpers/money';
import DashboardTable from '@/components/DashboardTable.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'OrdersTable',
  components: {
    ChecDataPill,
    ChecDataPillGroup,
    DashboardTable,
    TableRow,
    Timestamp,
  },
  mixins: [
    withPropDefinedState({
      state: ['items', 'totalCount', 'isLoading'],
      actions: {
        setPage: actions.SET_PAGE,
      },
    }),
  ],
  props: {
    customerId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setPage({
        page: 1,
        context: { customerId: this.customerId },
      });
    },
    pills({ status, status_payment: statusPayment, status_fulfillment: statusFulfillment }) {
      const colors = {
        status: {
          cancelled: 'gray',
        },
        paymentStatus: {
          not_paid: 'red',
          paid: 'green',
          partially_paid: 'orange',
          pending: 'orange',
          refunded: 'gray',
        },
        fulfillmentStatus: {
          fulfilled: 'green',
          not_fulfilled: 'red',
          partially_fulfilled: 'orange',
          returned: 'gray',
        },
      };

      return [{
        color: colors.paymentStatus[statusPayment],
        filled: status !== 'cancelled',
        text: this.$t(`order.paymentStatus.${statusPayment}`),
      }, {
        color: status === 'cancelled'
          ? colors.status.cancelled
          : colors.fulfillmentStatus[statusFulfillment],
        filled: false,
        text: this.$t(`order.fulfillmentStatus.${statusFulfillment}`),
      }];
    },
    money,
  },
};
</script>

<style lang="scss">
.orders-table {
  table {
    @apply w-full;
  }

  &__row {
    @apply cursor-pointer;

    &--sandbox {
      .orders-table__reference-cell {
        @apply p-4 border-l-4 border-hazard-gray;

        padding-left: calc(1rem - 4px);
      }

      td:first-child {
        @apply p-0 h-full;
      }
    }

    &--cancelled {
      .orders-table__reference {
        @apply line-through;
      }
    }
  }

  &__cancelled {
    @apply ml-2;
  }

  &__reference-number {
    @apply leading-tight text-sm;
  }

  &__reference {
    @apply caps-xxs leading-tight;
  }

  &__price {
    @apply text-green-600;
  }

  &__loading-row {
    @apply relative;

    &--loading {
      @apply py-16;
    }
  }

  &__empty-text {
    @apply text-gray-400;
  }

  &__sandbox-label {
    @apply text-orange-500;
  }
}

.filter-bar-dropdown-panel {
  &__header:first-child {
    @apply pt-2;
  }
}
</style>
