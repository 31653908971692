<template>
  <form class="customers-add-edit" @submit.prevent>
    <DashboardHeader :title="headerText" />
    <div class="customers-add-edit__content space-y-4 md:space-y-0">
      <div class="customers-add-edit__main space-y-4">
        <DetailsCard
          v-if="isAdding"
          v-model="customer"
          :is-loading="isSaving || loading"
          :saving="isSaving"
          :errors="validationErrors"
          @save-customer="handleSaveCustomer"
        />
        <OverviewCard
          v-else
          v-model="customer"
          :is-loading="isSaving || loading"
          :saving="isSaving"
          :errors="validationErrors"
          @save-customer="handleSaveCustomer"
        />
        <CustomerAddresses
          v-if="!isAdding && customer && customerId"
          :customer-id="customerId"
          :countries="countries"
          :subdivisions="subdivisions"
          :is-loading="isSaving || loading"
          state-key="customers/addresses"
          :context="{ customerId: customerId }"
        />
        <CustomerNotesCard
          v-if="!isAdding && customer && customerId"
          :customer-id="customerId"
        />
        <RecentOrders
          v-if="!isAdding && customer && customerId"
          :customer-id="customerId"
          :is-loading="isSaving || loading"
        />
      </div>
      <div class="customers-add-edit__side-bar space-y-4">
        <DefaultAddressCard
          v-if="!isAdding && customer && 'default_shipping' in customer"
          :address="customer.default_shipping"
          :countries="countries"
          context="shipping"
        />
        <DefaultAddressCard
          v-if="!isAdding && customer && 'default_billing' in customer"
          :address="customer.default_billing"
          :countries="countries"
          context="billing"
        />
        <ActionsCard
          v-if="!isAdding"
          :allow-delete="!isAdding"
          :saving="isSaving"
          @save-customer="handleSaveCustomer"
          @delete-customer="handleDeleteCustomer"
        />
        <NewActionsCard
          v-if="isAdding"
          :saving="isSaving"
          @save-customer="handleSaveCustomer"
        />
      </div>
    </div>
  </form>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import addNotification from '@/mixins/addNotification';
import formatSlug from '@/mixins/formatSlug';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import DashboardHeader from '@/components/DashboardHeader.vue';
import customerSchema from '../schemas/customer';
import ActionsCard from '../components/ActionsCard.vue';
import NewActionsCard from '../components/NewActionsCard.vue';
import DetailsCard from '../components/DetailsCard.vue';
import OverviewCard from '../components/OverviewCard.vue';
import RecentOrders from '../components/RecentOrders.vue';
import CustomerNotesCard from '../components/CustomerNotesCard.vue';
import DefaultAddressCard from '../components/DefaultAddressCard.vue';
import CustomerAddresses from '../components/CustomerAddresses.vue';

export default {
  name: 'CustomersEdit',
  components: {
    ActionsCard,
    CustomerAddresses,
    CustomerNotesCard,
    DashboardHeader,
    DefaultAddressCard,
    DetailsCard,
    RecentOrders,
    NewActionsCard,
    OverviewCard,
  },
  mixins: [
    addNotification,
    formatSlug,
    crud('customers', true),
    confirm,
  ],
  data() {
    return {
      customer: {
        external_id: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
      },
      validationErrors: {},
      isSaving: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'countries', 'subdivisions']),
    /**
     * Are we adding?
     *
     * @returns {boolean}
     */
    isAdding() {
      return this.$route.name === 'customers.add';
    },
    /**
     * Returns the header text for the page
     *
     * @returns {string}
     */
    headerText() {
      return this.isAdding ? this.$t('customer.add') : this.$t('customer.customerOverview');
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    if (this.isAdding) {
      this.loading = false;
      return;
    }

    this.load(this.customerId)
      .then(() => {
        this.customer = this.get(this.customerId);
      })
      .catch(() => {
        this.$router.push({ name: 'notFound' });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleSaveCustomer() {
      this.validationErrors = {};
      this.isSaving = true;
      validateSchemaRequest(customerSchema, this.customer, {
        abortEarly: false,
        context: { edit: true },
      })
        .then(
          (validatedData) => (this.isAdding
            ? this.create(validatedData, true, false)
            : this.update(this.customerId, validatedData, true, false)),
        )
        .then(({ id }) => {
          this.addNotification(
            this.isAdding ? this.$t('customer.added') : this.$t('customer.updated'),
          );
          if (this.isAdding) {
            this.$router.push({ name: 'customers.edit', params: { id } });
          }
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
          } else if (error.response.data.error && error.response.data.error.errors.email) {
            // Check for field level errors
            // todo make this better and more re-usable
            this.addNotification(this.$t('customer.emailTaken'), 'error');
          } else {
            this.addNotification(this.$t('customer.updateError'), 'error');
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    /**
     * Confirm and delete the customer.
     */
    async handleDeleteCustomer() {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('customer.confirmDelete'),
      )) {
        return;
      }

      // Send user immediately back to customers list
      const originalRoute = this.$router.currentRoute;
      const originalCustomerId = this.customerId;
      await this.$router.push({ name: 'customers.home' });

      if (originalCustomerId === undefined) {
        // Customer wasn't saved yet, do nothing.
        return;
      }

      this.delete(originalCustomerId)
        .then(() => {
          this.addNotification(this.$t('customer.deleted'));
        })
        .catch((error) => {
          this.addNotification(this.$t('customer.deleteFailed'), 'error');
          this.$router.push(originalRoute); // send user back to customer view
          throw error;
        });
    },
  },
};
</script>

<style lang="scss">
.customers-add-edit {
  @apply text-gray-500;

  &__side-bar {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm flex-shrink-0;
    }
  }

  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }

  .card {
    @apply mb-8;
  }

  .details-card {
    @apply p-4;

    .chec-header {
      @apply mb-4;
    }
  }
}
</style>
