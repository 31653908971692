<template>
  <ChecCard inner-class="customer-default-addresses" tailwind="p-4">
    <ChecHeader
      class="address-card__header"
      variant="card"
      :title="cardTitle"
    />
    <div class="customer-default-addresses__address">
      <div class="customer-default-addresses__name">
        {{ address.name }}
      </div>
      <div
        v-if="address.street !== null"
        class="customer-default-addresses__line"
      >
        {{ address.street }}
      </div>
      <div
        v-if="address.street_2 !== null"
        class="customer-default-addresses__line"
      >
        {{ address.street_2 }}
      </div>
      <div
        class="customer-default-addresses__line"
      >
        {{ cityRegionLine }}
      </div>
      <div
        class="customer-default-addresses__line"
      >
        {{ countryName }}
      </div>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
} from '@chec/ui-library';

export default {
  name: 'DefaultShippingAddress',
  components: {
    ChecCard,
    ChecHeader,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    context: String,
  },
  computed: {
    cardTitle() {
      return this.context === 'billing'
        ? this.$t('customer.defaultBillingAddress')
        : this.$t('customer.defaultShippingAddress');
    },
    /**
     * Filters out null values for city, region, and postcode, and concatenates them together with
     * relevant "glue".
     */
    cityRegionLine() {
      const { town_city: city, county_state: region, postal_zip_code: postCode } = this.address;
      const filterFunc = (candidate) => typeof candidate === 'string' && candidate.length > 0;

      return [
        [city, region].filter(filterFunc).join(', '),
        postCode,
      ].filter(filterFunc).join(' ');
    },
    /**
     * Maps a country code to a country name if possible, otherwise returns the country code
     *
     * @returns {String}
     */
    countryName() {
      if (this.countries && Object.hasOwnProperty.call(this.countries, this.address.country)) {
        return this.countries[this.address.country]; // E.g. "Canada"
      }
      return this.address.country || ''; // E.g. "CA"
    },
  },
};
</script>

<style lang="scss">
.customer-default-addresses {
  @apply flex flex-col gap-2;
}
</style>
