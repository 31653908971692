<template>
  <ChecCard
    inner-class="details-card"
    tailwind="p-4"
  >
    <ChecHeader
      :title="$t('customer.customerAddresses')"
      variant="card"
      header-tag="h3"
    >
      <ChecButton
        class="card__button"
        color="primary"
        variant="round"
        @click="handleAddAddress"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </ChecHeader>
    <template>
      <AddressesTable
        :customer-id="customerId"
        :countries="countries"
        state-key="customers/addresses"
        @edit-address="handleEditAddress"
        @delete-address="handleDelete"
      />
    </template>
    <ManagePanel
      v-if="showPanel"
      :active-address="activeAddress"
      :countries="countries"
      :customer-id="customerId"
      :subdivisions="subdivisions"
      :errors="validationErrors"
      :is-saving="isSaving"
      @handle-save="handleSave"
      @handle-delete="handleDelete"
      @close="handleClosePanel"
    />
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecHeader,
  ChecCard,
} from '@chec/ui-library';
import withPropDefinedState from '@/mixins/withPropDefinedState';
import confirm from '@/mixins/confirm';
import addNotification from '@/mixins/addNotification';
import { actions } from '@/lib/pagination';
import AddressesTable from './AddressesTable.vue';
import ManagePanel from './ManagePanel.vue';

export default {
  name: 'CustomerAddresses',
  components: {
    ChecButton,
    ChecHeader,
    ChecCard,
    AddressesTable,
    ManagePanel,
  },
  mixins: [
    addNotification,
    confirm,
    withPropDefinedState({
      state: ['items', 'totalCount', 'isLoading'],
      actions: {
        setPage: actions.SET_PAGE,
        createAddress: actions.ADD_NEW_ITEM,
        updateAddress: actions.UPDATE_ITEM,
        deleteAddress: actions.DELETE_ITEM,
      },
    }),
  ],
  props: {
    context: {
      type: Object,
      required: true,
    },
    customerId: {
      type: String,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    subdivisions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPanel: false,
      activeAddress: {},
      validationErrors: {},
      isSaving: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setPage({
        page: 1,
        context: this.context,
      });
    },
    handleClosePanel() {
      this.showPanel = false;
    },
    handleEditAddress(address) {
      this.activeAddress = address;
      this.showPanel = true;
    },
    handleAddAddress() {
      this.activeAddress = {};
      this.showPanel = true;
    },
    handleSave(addressData) {
      this.isSaving = true;
      if (addressData.isAdding) {
        this.createAddress({
          item: {
            ...addressData.address,
          },
          prepend: true,
        })
          .then(() => {
            this.addNotification(this.$t('customer.addressAdded'));
            this.handleClosePanel();
          })
          .catch((error) => {
            this.addNotification(this.$t('customer.addressError'));
            this.validationErrors = error.errors;
            throw error;
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
      if (!addressData.isAdding) {
        this.updateAddress({
          id: addressData.address.id,
          item: {
            ...addressData.address,
          },
          prepend: true,
        })
          .then(() => {
            this.addNotification(this.$t('customer.addressUpdated'));
            this.handleClosePanel();
          })
          .catch((error) => {
            this.addNotification(this.$t('customer.addressError'));
            this.validationErrors = error.errors;
            throw error;
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
    async handleDelete(id) {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('customer.confirmAddressDelete'),
      )) {
        return;
      }
      this.isSaving = true;
      this.deleteAddress({ id }).then(() => {
        this.isSaving = false;
      });
      this.addNotification(this.$t('customer.addressDeleted'));
    },
  },
};
</script>
