<template>
  <ChecSlideoutPanel
    :title="$t('customer.customerDetails')"
    close-on-overlay-click
    @close="close"
  >
    <div class="manage-customer-details__content space-y-8">
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            :value="customer.firstname"
            :label="$t('general.firstName')"
            name="firstname"
            :variant="errors && errors['firstname'] ? 'error' : ''"
            @input="value => change('firstname', value)"
          />
          <span v-if="errors['firstname']" class="input-wrapper__error">
            {{ errors['firstname'] }}
          </span>
        </div>
        <div class="input-wrapper">
          <TextField
            :value="customer.lastname"
            :label="$t('general.lastName')"
            name="lastname"
            :variant="errors && errors['lastname'] ? 'error' : ''"
            @input="value => change('lastname', value)"
          />
          <span v-if="errors['lastname']" class="input-wrapper__error">
            {{ errors['lastname'] }}
          </span>
        </div>
      </div>
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            :value="customer.email"
            :label="$t('general.emailAddress')"
            type="email"
            name="email"
            :variant="errors && errors['email'] ? 'error' : ''"
            required
            @input="value => change('email', value)"
          />
          <span v-if="errors['email']" class="input-wrapper__error">
            {{ errors['email'] }}
          </span>
        </div>
        <div class="input-wrapper">
          <TextField
            :value="customer.phone"
            :label="$t('general.phoneNumber')"
            name="phone"
            :variant="errors && errors['phone'] ? 'error' : ''"
            @input="value => change('phone', value)"
          />
          <span v-if="errors['phone']" class="input-wrapper__error">
            {{ errors['phone'] }}
          </span>
        </div>
      </div>
      <div class="input-wrapper">
        <TextField
          :value="customer.external_id"
          :label="$t('customer.externalId')"
          name="external_id"
          :variant="errors && errors['external_id'] ? 'error' : ''"
          @input="value => change('external_id', value)"
        />
        <span v-if="errors['external_id']" class="input-wrapper__error">
          {{ errors['external_id'] }}
        </span>
      </div>
    </div>
    <template #toolbar>
      <div class="manage-customer-details__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="close">
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          color="primary"
          :disabled="saving"
          @click="handleSave"
        >
          {{ saveLabel }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecSlideoutPanel,
  TextField,
} from '@chec/ui-library';

export default {
  name: 'DetailsPanel',
  components: {
    ChecButton,
    ChecSlideoutPanel,
    TextField,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    saving: Boolean,
  },
  computed: {
    saveLabel() {
      return this.saving ? this.$t('general.saving') : this.$t('general.saveChanges');
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('change', {
        ...this.customer,
        [prop]: value,
      });
    },
    close() {
      this.$emit('close');
    },
    handleSave() {
      this.$emit('handle-save');
    },
  },
};
</script>

<style lang="scss">
.manage-customer-details {
  &__toolbar,
  &__hero-toolbar {
    @apply flex justify-end w-full;
  }

  &__hero-toolbar {
    @apply mt-8;
  }
}
</style>
