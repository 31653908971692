<template>
  <ChecSlideoutPanel
    :title="title"
    close-on-overlay-click
    @close="close"
  >
    <div class="manage-customer-address__content space-y-8">
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            v-model="editingAddress.name"
            name="address-name"
            required
            :label="$t('general.name')"
          />
          <span v-if="errors.name" class="input-wrapper__error">
            {{ errors.name }}
          </span>
        </div>
        <div class="input-wrapper">
          <TextField
            v-model="editingAddress.street"
            name="address-street"
            required
            :label="$t('general.address.street')"
          />
          <span v-if="errors.street" class="input-wrapper__error">
            {{ errors.street }}
          </span>
        </div>
      </div>
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            v-model="editingAddress.street_2"
            name="address-street2"
            :label="$t('general.address.street_line_2')"
          />
          <span v-if="errors.street_2" class="input-wrapper__error">
            {{ errors.street_2 }}
          </span>
        </div>
        <div class="input-wrapper">
          <TextField
            v-model="editingAddress.town_city"
            name="address-town"
            required
            :label="$t('general.address.city')"
          />
          <span v-if="errors.town_city" class="input-wrapper__error">
            {{ errors.town_city }}
          </span>
        </div>
      </div>
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <ChecDropdown
            v-if="Object.values(countriesOptions).length"
            v-model="selectedCountry"
            name="address-country"
            required
            :options="countriesOptions"
            :label="$t('general.address.country')"
            :search-value="searchCountry"
            show-search
            @search="(value) => searchCountry = value"
          />
          <span v-if="errors.country" class="input-wrapper__error">
            {{ errors.country }}
          </span>
        </div>
        <div class="input-wrapper">
          <ChecDropdown
            v-if="Object.values(subdivisionOptions).length"
            v-model="selectedRegion"
            :label="$t('general.address.provinceState')"
            name="state"
            :variant="errors.county_state ? 'error' : ''"
            :options="subdivisionOptions"
            :search-value="searchRegion"
            show-search
            @search="(value) => searchRegion = value"
          />
          <TextField
            v-else
            :label="$t('address.state')"
            variant="disabled"
          />
          <span v-if="errors.county_state" class="input-wrapper__error">
            {{ errors.county_state }}
          </span>
        </div>
      </div>
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            v-model="editingAddress.postal_zip_code"
            name="address-postal-zip"
            required
            :label="$t('general.address.postalZip')"
          />
          <span v-if="errors.postal_zip_code" class="input-wrapper__error">
            {{ errors.postal_zip_code }}
          </span>
        </div>
      </div>
      <div class="input-wrapper">
        <TextField
          v-model="editingAddress.delivery_instructions"
          name="address-delivery-instructions"
          multiline
          :label="$t('general.address.delivery_instructions')"
        />
        <span v-if="errors.delivery_instructions" class="input-wrapper__error">
          {{ errors.delivery_instructions }}
        </span>
      </div>
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <ChecSwitch
            v-model="editingAddress.default_billing"
            name="address-default-billing"
          >
            {{ $t('customer.defaultBillingAddress') }}
          </ChecSwitch>
        </div>
        <div class="input-wrapper">
          <ChecSwitch
            v-model="editingAddress.default_shipping"
            name="address-default-shipping"
          >
            {{ $t('customer.defaultShippingAddress') }}
          </ChecSwitch>
        </div>
      </div>
    </div>
    <template #toolbar>
      <ChecButton
        v-if="!isAdd"
        outline
        color="red"
        icon="trash"
        :disabled="isSaving"
        @click="handleDelete(address.id)"
      >
        {{ $t('general.delete' ) }}
      </ChecButton>
      <div class="manage-customer-address__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="close">
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          color="primary"
          :disabled="isSaving"
          @click="handleSave"
        >
          {{ saveLabel }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecDropdown,
  ChecSlideoutPanel,
  TextField,
  ChecSwitch,
} from '@chec/ui-library';
import actions from '@/store/actions';

export default {
  name: 'ManagePanel',
  components: {
    ChecButton,
    ChecSlideoutPanel,
    TextField,
    ChecSwitch,
    ChecDropdown,
  },
  props: {
    activeAddress: Object,
    countries: {
      type: Object,
      required: true,
    },
    subdivisions: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    isSaving: Boolean,
  },
  data() {
    return {
      editingAddress: {
        country: '',
        county_state: '',
        default_billing: false,
        default_shipping: false,
        delivery_instructions: '',
        id: '',
        meta: '',
        name: '',
        postal_zip_code: '',
        street: '',
        street_2: '',
        town_city: '',
      },
      selectedCountry: this.activeAddress.country || '',
      selectedRegion: this.activeAddress.county_state || '',
      searchCountry: '',
      searchRegion: '',
    };
  },
  computed: {
    isAdd() {
      return this.editingAddress.id === '';
    },
    saveLabel() {
      return this.isSaving ? this.$t('general.saving') : this.$t('general.saveChanges');
    },
    title() {
      return !this.isAdd
        ? this.$t('customer.editCustomerAddress')
        : this.$t('customer.addCustomerAddress');
    },
    /**
     * Gets a list of available countries, formatted as options for a dropdown
     *
     * @returns {object[]}
     */
    countriesOptions() {
      // Filter countries based on a search (if there is one)
      const filteredCountries = this.searchCountry.length > 0
        ? Object.entries(this.countries)
          // Make country label lower case to prevent case sensitivity in search input
          .filter((country) => country[1].toLowerCase().includes(this.searchCountry.toLowerCase()))
        : Object.entries(this.countries);

      return filteredCountries.map(([value, label]) => ({ value, label }));
    },
    /**
     * Gets a list of available states/regions/subdivisions for the currently selected country
     *
     * @returns {object[]}
     */
    subdivisionOptions() {
      const countryCode = this.selectedCountry;

      if (!this.subdivisions[countryCode]) {
        return [];
      }

      return Object.entries(this.subdivisions[countryCode])
        .map(([key, value]) => ({ value: key, label: value }));
    },
  },
  watch: {
    selectedCountry: {
      handler(val, oldVal) {
        if (oldVal !== val) {
          this.getRegionsForCountry(val);
          this.change('country', val);
        }
        // If not just mounted
        if (typeof oldVal !== 'undefined') {
          // Unselect whatever state was selected
          this.selectedRegion = '';
          this.searchRegion = '';
        }
      },
      immediate: true,
    },
    selectedRegion: {
      handler(val) {
        this.change('county_state', val);
      },
    },
  },
  mounted() {
    if (this.activeAddress.id) {
      this.editingAddress = this.activeAddress;
    }
  },
  methods: {
    /**
     * Get a list of available regions from the API for the given country code
     *
     * @param {string} countryCode
     */
    getRegionsForCountry(countryCode) {
      if (countryCode === '') {
        // Do nothing
        return;
      }

      if (this.subdivisions[countryCode]) {
        // Already exists in state
        return;
      }

      this.loading = true;
      this.$store.dispatch(actions.FETCH_SUBDIVISIONS, countryCode)
        .finally(() => {
          this.loading = false;
        });
    },
    change(prop, value) {
      this.editingAddress[prop] = value;
    },
    close() {
      this.$emit('close');
    },
    handleSave() {
      this.$emit('handle-save', {
        address: this.editingAddress,
        isAdding: this.isAdd,
      });
    },
    handleDelete(id) {
      this.$emit('handle-delete', id);
    },
  },
};
</script>

<style lang="scss">
.manage-customer-address {
  &__toolbar,
  &__hero-toolbar {
    @apply flex justify-end w-full;
  }

  &__hero-toolbar {
    @apply mt-8;
  }
}
</style>
