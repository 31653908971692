<template>
  <DashboardTable
    class="customer-addresses-table"
    :align-right-after="4"
    :columns="[
      $t('general.name'),
      $t('customer.address'),
      $t('customer.defaultBilling'),
      $t('customer.defaultShipping'),
      $t('general.options'),
    ]"
    state-key="customers/addresses"
  >
    <TableRow
      v-for="address in items"
      :id="address.id"
      :key="address.id"
      class="customer-addresses__row-table"
      @click="() => handleEdit(address)"
    >
      <td>
        <div class="customer-addresses__reference-table-number">
          {{ address.name }}
        </div>
      </td>
      <td>
        <div class="customer-addresses-table__address">
          <div
            v-if="address.street !== null"
            class="customer-addresses-table__line"
          >
            {{ address.street }}
          </div>
          <div
            v-if="address.street_2 !== null"
            class="customer-addresses-table__line"
          >
            {{ address.street_2 }}
          </div>
          <div
            class="customer-addresses-table__line"
          >
            {{ cityRegionLine(address) }}
          </div>
          <div
            class="customer-addresses-table__line"
          >
            {{ countryName(address.country) }}
          </div>
        </div>
      </td>
      <td>
        <ChecDataPill
          :color="address.default_shipping ? 'green' : 'gray'"
          class="customer-addresses__is-default"
        >
          {{
            address.default_shipping
              ? $t('general.yes')
              : $t('general.no')
          }}
        </ChecDataPill>
      </td>
      <td>
        <ChecDataPill
          :color="address.default_billing ? 'green' : 'gray'"
          class="customer-addresses__is-default"
        >
          {{
            address.default_billing
              ? $t('general.yes')
              : $t('general.no')
          }}
        </ChecDataPill>
      </td>
      <td>
        <ChecOptionsMenu class="inline-block">
          <ChecOption @option-selected="() => handleEdit(address)">
            {{ $t('general.edit') }}
          </ChecOption>
          <ChecOption destructive @option-selected="() => handleDelete(address.id)">
            {{ $t('general.delete') }}
          </ChecOption>
        </ChecOptionsMenu>
      </td>
    </TableRow>
  </DashboardTable>
</template>

<script>
import {
  ChecOptionsMenu,
  ChecOption,
  ChecDataPill,
} from '@chec/ui-library';
import withPropDefinedState from '@/mixins/withPropDefinedState';
import { actions } from '@/lib/pagination';
import DashboardTable from '@/components/DashboardTable.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'AddressesTable',
  components: {
    ChecDataPill,
    ChecOptionsMenu,
    ChecOption,
    DashboardTable,
    TableRow,
  },
  mixins: [
    withPropDefinedState({
      state: ['items', 'totalCount', 'isLoading'],
      actions: {
        setPage: actions.SET_PAGE,
      },
    }),
  ],
  props: {
    customerId: {
      type: String,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setPage({
        page: 1,
        context: { customerId: this.customerId },
      });
    },
    /**
     * Maps a country code to a country name if possible, otherwise returns the country code
     *
     * @returns {String}
     */
    countryName(country) {
      if (this.countries && Object.hasOwnProperty.call(this.countries, country)) {
        return this.countries[country]; // E.g. "Canada"
      }
      return country || ''; // E.g. "CA"
    },
    /**
     * Filters out null values for city, region, and postcode, and concatenates them together with
     * relevant "glue".
     */
    cityRegionLine(address) {
      const { town_city: city, county_state: region, postal_zip_code: postCode } = address;
      const filterFunc = (candidate) => typeof candidate === 'string' && candidate.length > 0;

      return [
        [city, region].filter(filterFunc).join(', '),
        postCode,
      ].filter(filterFunc).join(' ');
    },
    handleEdit(address) {
      this.$emit('edit-address', address);
    },
    handleDelete(id) {
      this.$emit('delete-address', id);
    },
  },
};
</script>

<style lang="scss">
.customer-addresses-table {
  table {
    @apply w-full;
  }

  &__row {
    @apply cursor-pointer;

    &__loading-row {
      @apply relative;

      &--loading {
        @apply py-16;
      }
    }

    &__empty-text {
      @apply text-gray-400;
    }
  }

  &__address {
    @apply text-xs;
  }
}
</style>
